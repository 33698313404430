import { Box, Flex, Text } from "@chakra-ui/react";
import { useMotionValueEvent, useScroll } from "framer-motion";
import React, { useState } from "react";

const LABELS = {
    mobile: [
        {label: "Worked", color: "#ABECFD", toValue: 120},
        {label: "Holiday", color: "#FFD56D", toValue: 150},
        {label: "Illness", color: "#FFDDDC", toValue: 180},
        {label: "Babysitting", color: "#5CE892", toValue: 210}
    ],
    full: [
        {label: "Worked", color: "#ABECFD", toValue: 280},
        {label: "Holiday", color: "#FFD56D", toValue: 330},
        {label: "Illness", color: "#FFDDDC", toValue: 380},
        {label: "Babysitting", color: "#5CE892", toValue: 490}
    ]
};

export default({isMobile}) => {
    const {scrollY} = useScroll();
    const [value, setValue] = useState(0);

    useMotionValueEvent(scrollY, "change", (latest) => {
        setValue(latest);
    });

    const getLabelsToUse = () => {
        if (isMobile) {
            return LABELS.mobile;
        }

        return LABELS.full;
    }

    const getLabelAndColor = () => {
        for (const label of getLabelsToUse()) {
            if (value < label.toValue) {
                return label;
            }
        }
        
        return getLabelsToUse()[getLabelsToUse().length - 1];
    };

    const labelAndColor = getLabelAndColor();

    return (
        <Flex marginTop={isMobile ? "5px" : "10px"} direction="row" justifyContent="center" alignItems="center">
            <Box width={isMobile ? "8px" : "16px"} borderRadius={isMobile ? "2px" :"4px"} height={isMobile ? "8px" : "16px"} backgroundColor={labelAndColor.color}>

            </Box>
            <Text marginLeft="5px" fontFamily="Fuzzy Bubbles" fontSize={isMobile ? "xs" : "md"}>{labelAndColor.label}</Text>
        </Flex>
    );
};