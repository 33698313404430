import { Flex, Image, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import PhoneFrame from "../svg/PhoneFrame";

const FULL_WIDTH = 389;
const FULL_HEIGHT = 802;

const SCREENSHOT_MULTIPLIER =  0.84;

const MOBILE_MULTIPLIER = 0.7;

const SIZING = {
    mobile: {
        frame: {
            width: FULL_WIDTH * MOBILE_MULTIPLIER,
            height: FULL_HEIGHT * MOBILE_MULTIPLIER
        }
    },
    full: {
        frame: {
            width: FULL_WIDTH,
            height: FULL_HEIGHT
        }
    }
}

export default({source}) => {
    const [isMobile] = useMediaQuery("(max-width: 768px)");

    const sizing = isMobile ? SIZING.mobile : SIZING.full;

    return (
        <Flex position="relative" width={sizing.frame.width} height={sizing.frame.height}>
            <Flex position="absolute" top="-4%" left={0} bottom={0} right={0} zIndex={1} alignItems="center" justifyContent="center">
                <Image width={sizing.frame.width * SCREENSHOT_MULTIPLIER} height={sizing.frame.height *  SCREENSHOT_MULTIPLIER} src={source}/>
            </Flex>
            <Flex position="absolute" top={0} left={0} bottom={0} right={0} zIndex={2}>
                <PhoneFrame
                    width={sizing.frame.width}
                    height={sizing.frame.height}
                />
            </Flex>
            
        </Flex>
        
    )
};