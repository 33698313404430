import { Flex } from "@chakra-ui/react";
import * as React from "react"
import RightArrow from "./RightArrow";

export default({width, height}) => {
    return (
        <Flex transform="scaleX(-1)">
            <RightArrow width={width} height={height}/>
        </Flex>
    )
};