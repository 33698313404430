import * as React from "react"

const RightArrow = ({width, height}) => {
    return (
        <svg viewBox="0 0 78 118" width={width || 78} height={height || 118} xmlns="http://www.w3.org/2000/svg">
            <path
            d="M75 1C73.493 44.052 50.341 85.987 7.848 105.457c-6.158 2.822 2.801-24.411 7.07-19.133C47.464 126.556-44.33 97.944 39.159 115"
            stroke="#020202"
            strokeWidth={5.4}
            fill="none"
            fillRule="evenodd"
            />
        </svg>
    )
}

export default RightArrow;