import * as React from "react"

const PhoneFrame = ({width, height}) => (
  <svg
    width={width || 583}
    height={height || 1134}
    viewBox="0 0 583 1134"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <filter
        x="-11.3%"
        y="-3.5%"
        width="122.5%"
        height="110.9%"
        filterUnits="objectBoundingBox"
        id="a"
      >
        <feOffset dy={21} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={16}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feOffset dy={3} in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur
          stdDeviation={5.5}
          in="shadowOffsetOuter2"
          result="shadowBlurOuter2"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          in="shadowBlurOuter2"
          result="shadowMatrixOuter2"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <filter
        x="-8.1%"
        y="-2%"
        width="116.2%"
        height="107.9%"
        filterUnits="objectBoundingBox"
        id="c"
      >
        <feGaussianBlur
          stdDeviation={6.5}
          in="SourceAlpha"
          result="shadowBlurInner1"
        />
        <feOffset dy={-7} in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          operator="arithmetic"
          k2={-1}
          k3={1}
          result="shadowInnerInner1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          in="shadowInnerInner1"
        />
      </filter>
      <path
        d="M443.4 0C485.153 0 519 33.847 519 75.6v918.8c0 41.753-33.847 75.6-75.6 75.6H75.6C33.847 1070 0 1036.153 0 994.4V75.6C0 33.847 33.847 0 75.6 0h367.8Zm1.704 23.516H73.896c-27.836 0-50.4 22.565-50.4 50.4v922.168c0 27.835 22.564 50.4 50.4 50.4h371.208c27.836 0 50.4-22.565 50.4-50.4V73.916c0-27.835-22.564-50.4-50.4-50.4Z"
        id="b"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(32 11)">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <use fill="#FFF" xlinkHref="#b" />
        <use fill="#000" filter="url(#c)" xlinkHref="#b" />
      </g>
      
    </g>
  </svg>
)

export default PhoneFrame;
