import { Image } from "@chakra-ui/react";
import React from "react";

import logo from '../nannydays_logo.svg';

export default() => {
    return (
        <Image 
            src={logo} 
            alt="Logo"/>
    );
};