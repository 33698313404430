import { Button, Flex } from "@chakra-ui/react";
import React from "react";
import Logo from "./Logo";

export default() => {
    if (window.location.href.indexOf("main") < 0) {
        return null;
    }
    
    return (
        <Flex padding="30px" direction="row" justifyContent="space-between" alignItems="center">
            <Logo/>
            <Button
                colorScheme="pink">
                    Get
                </Button>
        </Flex>
    );
};