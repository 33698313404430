import React from "react";

import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";

import {ChakraProvider, extendTheme, Box, Flex} from "@chakra-ui/react";

import Placeholder from "./Placeholder";
import Main from "./Main";
import Header from "./components/Header";
import "@fontsource/cabin";
import "@fontsource/fuzzy-bubbles";

export default() => {

    const theme = extendTheme({
        colors: {
          pink: {
           main: "#FFAAA8",
           50: "#FFAAA8",
           100: "#FFAAA8",
           200: "#FFAAA8",
           300: "#FFAAA8",
           400: "#FFAAA8",
           500: "#FFAAA8",
           600: "#FFAAA8",
           700: "#FFAAA8",
           800: "#FFAAA8",
           900: "#FFAAA8"
         }
       },
       fonts: {
        heading: `'Cabin', sans-serif`,
        body: `'Cabin', sans-serif`,
      },
     });

    const router = createBrowserRouter([
        {
          path: "/",
          element: <Placeholder/>
        },
        {
            path: "/main",
            element: <Main/>
        }
    ]);

    return (
        <ChakraProvider theme={theme}>
            <Flex direction="column">
                <Header/>
                <Flex direction="column" alignItems="center">
                    <Box maxW={{"base": "320px", "md": "800px"}}>
                        <RouterProvider router={router} />
                    </Box>
                </Flex>
                
            </Flex>
            
        </ChakraProvider>
        
    )
};