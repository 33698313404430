import React from "react";
import { Flex, Heading, Text, useMediaQuery} from "@chakra-ui/react";

import RightArrow from "./svg/RightArrow";

import {
    useScroll,
    motion,
    useTransform
} from 'framer-motion';

import PhoneScreenshot from "./components/PhoneScreenshot";
import calendarScreen from "./images/calendar_screen@2x.png"
import LeftArrow from "./svg/LeftArrow";
import TimeTypeLabel from "./components/TimeTypeLabel";

export default() => {

    const { scrollYProgress, scrollY } = useScroll();

    const [isMobile] = useMediaQuery("(max-width: 768px)");
 
    return (
        <Flex direction="column" alignItems="center" paddingBottom="500px">
            <Flex maxWidth="500px" marginTop="30px" alignItems="center" direction="column">
                <Heading textAlign="center" fontWeight="700" size="2xl" marginBottom="20px">Nanny time tracking</Heading>
                
                <Text textAlign="center" color="gray.600" fontSize="xl">Track your nanny’s time, holidays and expenses all through our beautiful new app.</Text>
                
                
                <Flex position="relative">
                    <motion.div style={{
                        marginTop: 0,
                        marginBottom: 200,
                        y: useTransform(scrollY, [0, 200, 300, 500], isMobile ? [1, 1, 1, 1] : [1, 1, 100, 100]),
                        opacity: useTransform(scrollYProgress, [0, 1], [0.8, 1]),
                        scale: useTransform(scrollY, [0, 200], [0.9, 1])
                    }}>
                        
                        <PhoneScreenshot
                            source={calendarScreen}
                        />
                        

                    </motion.div>

                    <Flex visibility={isMobile ? "hidden" : null} position="absolute" top={isMobile ? (535 / 2) - 150 : (802 / 2) - 150} right={isMobile ? -5 : -10} direction="column">
                        <motion.div style={{
                                x: useTransform(scrollY, [0, 100], [600, 0]),
                                opacity: useTransform(scrollY, [0, 60, 100], [0, 0, 1]),
                                scale: useTransform(scrollY, [0, 50, 100], [0, 0, 1]),
                                //y: scrollYProgress
                            }}>
                                <RightArrow width={isMobile ? 20 : 40}/>
                                <Text fontSize={isMobile ? "sm" : "lg"} fontFamily="Fuzzy Bubbles" fontWeight="900" position="absolute" top={isMobile ? 5 : 0} left={0}>Calendar</Text>
                        </motion.div>
                    </Flex>

                    
                    <Flex visibility={isMobile ? "hidden" : null} position="absolute" top={isMobile ? (535 / 2) + 50 : (802 / 2) + 100} left={isMobile ? -5 : -10} direction="column">
                        <motion.div style={{
                                x: useTransform(scrollY, isMobile ? [0, 50] : [0, 50], [-600, 0]),
                                opacity: useTransform(scrollY, isMobile ? [0, 50, 100] : [0, 100, 200], [0, 0, 1]),
                                scale: useTransform(scrollY, isMobile ? [0, 50, 100] : [0, 100, 200], [0, 0, 1]),
                                y: useTransform(scrollY, isMobile ? [0, 200] : [0, 300], isMobile ? [0, -200] : [0, -150]),
                            }}>
                                <LeftArrow width={isMobile ? 20 : 40}/>
                                <Flex  position="absolute" direction="column" justifyContent="center" alignItems="center" top={isMobile ? "-10px" : "-40px"} left="-50px" right="0px">
                                    <Text fontSize={isMobile ? "sm" : "lg"} fontFamily="Fuzzy Bubbles" fontWeight="900">Track</Text>
                                
                                    <TimeTypeLabel
                                        isMobile={isMobile}
                                    />
                                </Flex>
                                
                        </motion.div>
                    </Flex>
                    



                </Flex>

                
                
            
                
            </Flex>
        </Flex>
    );
};